.addinputField {
  width: 613px;
  height: 38px;
  margin-left: 30px;
  top: 108px;
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
}
.ant-select-selection-overflow {
  height: 38px !important;
}
.classic {
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  width: 613px;
  height: 38px;
  font-size: 18px;
  margin-left: 30px;
  font-size: 14px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.classic:focus {
  min-width: 183px;
}
.signup-select {
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  width: 183px !important;
  height: 38px;
  font-size: 18px;
  margin-left: 30px;
  font-size: 14px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}

.signup-select:focus {
  min-width: 183px;
}
.option_scroll {
  height: 50px;
  overflow-y: scroll;
}
.dateicon {
  font-size: 20px;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-left: 10px;
  background: #da8517;
  color: #ffffff;
  border: 1px solid #da8517;
  border-radius: 4px;
}
.css-dev-only-do-not-override-1i9hnpv.ant-picker {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 11px;
}
.studyname {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #000000;
}
.css-dev-only-do-not-override-1i9hnpv.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 24px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "opensas", sans-serif;
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  height: 38px;
}
.ant-select-arrow {
  color: rgba(0, 0, 0, 0.906) !important;
  font-size: 10px !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}
.ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #bbb8b8 !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.browse_edit {
  margin-left: 80px;
  margin-right: 40px;
  position: relative;
}
.browser_edit {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  grid-gap: 10px;
  position: relative;
}
.browserbtnEdit {
  background: #093975;
  border-radius: 4px;
  border: 1px solid black;
  color: white;
  margin-left: 30px;
  font-family: "openSans", sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
}
.deselect_btn_edit {
  margin-left: 10px;
  border: none;
  background-color: #093975;
  color: #ffffff;
  text-align: center;
  margin-top: -10px;
  font-weight: 600;
}
.browserbtnEdit:hover {
  color: white !important;
  border: 1px solid black !important;
}
.browsebtn {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.addinputField {
  width: 613px;
  height: 38px;
  margin-left: 30px;
  top: 108px;
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
}

.dateicon {
  font-size: 20px;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-left: 10px;
  background: #da8517;
  color: #ffffff;
}
.css-dev-only-do-not-override-1i9hnpv.ant-picker {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 11px;
}
.studyname {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  margin-top: 15px;
  color: #000000;
}
.css-dev-only-do-not-override-1i9hnpv.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 24px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "opensas", sans-serif;
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  border: none !important;
  height: 38px;
}
.ant-select-arrow {
  color: rgba(0, 0, 0, 0.906) !important;
  font-size: 10px !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}
.ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #bbb8b8 !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 38px !important;
}

.copyUrl {
  display: flex;
}
.copysuccess {
  margin-left: 580px;
  color: #6d6c6c;
  font-size: 14px;
  position: absolute;
  right: 0px;
  top: 40px;
}
.url_study {
  position: relative;
  display: flex;
}
.copyIcon {
  font-size: 20px;
  padding: 8px 10px;
  background-color: #da8517;
  color: white;
  position: absolute;
  right: 0px;
  border: 1px solid #da8517;
  border-radius: 4px;
  top: 0px;
}
.toastMessage {
  color: rgb(42, 41, 41);
}
.editStudyCancelBtn {
  background: #ffffff;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #093975;
  padding: 10px 25px;
  border-radius: 4px;
}
.editStudySaveBtn {
  background: #093975;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.editStudySaveBtn_loading {
  background: #093975;
  opacity: 0.6;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.browsedEditImages {
  margin-top: 20px;
  margin-left: 30px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 5px;
  grid-gap: 10px;
  position: relative;
}
.selected_count_edit {
  font-size: 20px;
  font-weight: 600;
  color: #da8517;
  padding-left: 10px;
}
.AddEditContainerTwo {
  width: 800px;
}
/* .borderForImagesEdit {
  border: 1px solid rgb(214, 206, 206);
} */
.wrongIcon_for_edit {
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
}
.image_editIcon {
  position: relative;
  width: 100px;
}
.browsed_imaged_imagesedit {
  border: 1px solid rgb(220, 212, 212);
}
.timeout_edit {
  margin-left: 30px;
}
.edit_inputFieldTwo {
  width: 456px;
  height: 38px;

  top: 108px;
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.selectIn_EditStudyTwo {
  width: 230px;
}
.css-e363es-MuiFormControl-root {
  margin: 0px 8px !important;
}
.cancel_btn_in_editTwo {
  border: none;
  background-color: #fff;
}
.x_editbtn {
  font-size: 20px;
  color: #6d6c6c;
  background-color: #fff;
}
.edit_question_two_btn {
  border: none;
  margin-left: 20px;
  margin-top: 15px;
  background-color: white;
}
.edit_question_Cancel_two {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-right: 5px;
}
.edit_question_two {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}

.edit_question_two:hover {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
.editinputFieldTwo {
  width: 456px;
  height: 38px;
  top: 108px;
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.flex_grow {
  flex-grow: 0.9 !important;
}
.overall_explination_block {
  margin-left: 30px;
  margin-bottom: 10px;
}
.overall_comparision_block {
  margin-left: 30px;
  height: 140px;
  margin-bottom: 10px;
}
.edit_backgroundLoading_images {
  height: 21.8vw;
  background-image: url(../../../images/loadtwosmall.gif);
  background-repeat: no-repeat;
  background-position: center;
}
.Browsed_images_loading_two {
  height: 6vw;
  background-image: url(../../../images/loadtwosmall.gif);
  background-repeat: no-repeat;
  background-position: center;
}

.react_quill_edit_explination {
  width: 456px;
  height: 100px;
  margin-bottom: 40px;
}
.overalledit_comparision_block {
  margin-left: 30px;
  height: 100px;
  margin-bottom: 50px;
}
.eidt_error_message_form {
  color: #ff371b;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 8px;
}
