/* .carouselContainer {
  height: 375px;
} */
.carouselMargin {
  padding-top: 100px;
}

@media screen and (max-width: 408px) {
  .carouselMargin {
    padding-top: 74px;
  }
}
@media screen and (max-width: 548px) {
  .carouselMargin {
    padding-top: 54px;
  }
}
.bghjHX {
  overflow: hidden;
  margin: 0 0px;
}
