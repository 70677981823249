.TheoryDivision {
  padding-left: 50px;
  padding-top: 80px;
}
@media screen and (max-width: 408px) {
  .TheoryDivision {
    padding-left: 0px;
  }
}
.blackHeading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  margin-right: 30px;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #000000;
}
.skyblueHeading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  color: #3787c5;

  text-align: justify;
  letter-spacing: 0.02em;
}

.blackHeading2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;

  text-align: justify;
  letter-spacing: 0.02em;

  color: #000000;
}
.blackheadingBold {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 38px;

  text-align: justify;
  letter-spacing: 0.02em;

  color: #000000;
}
.skyblueHeadingBold {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 38px;
  /* or 211% */

  text-align: justify;
  letter-spacing: 0.02em;

  color: #3787c5;
}

.blackHeading3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;

  letter-spacing: 0.02em;

  color: #000000;
}
.blackHeading4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #000000;
  padding-top: 10px;
}
