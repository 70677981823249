.edit {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: blueviolet;
  border-radius: 20px;
  border: none;
}
.edit::before {
  width: 20px;
  height: 10px;
  background-color: blue;
}

.carusel {
  width: 80vh;
  margin-bottom: 20px;
  margin-left: 30px;
}
.images_in_carousel_height {
  height: 70vh;
  background-image: url(../../../images/loadtwo.gif);
  background-repeat: no-repeat;
  background-position: center;
}
/* .slider_image {
  border: 1px solid rgb(223, 217, 217);
} */
.carousel-indicators [data-bs-target] {
  display: none;
}

.carousel-control-next-icon {
  color: black;
  background-color: black;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: block;
  /* width: 4rem;
  height: 4rem; */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  color: black;
  background-color: black;
}
.question1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #000000;
}
.questions {
  height: 39px;
  left: 40px;
  top: 0px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 39px;
  letter-spacing: 0.02em;
  margin-left: 20px;
  color: #000000;
}
.radio-btn {
  margin-left: 20px;
}
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #565656;
  margin-right: 5px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
}

input[type="radio"]:checked {
  border: 6px solid #093975;
}
.nextBtnSlider {
  padding: 0px 24px;
  background: #093975;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 20px;
  font-weight: 700;
  font-size: 15px;
}
.nextBtnSlider_loading {
  padding: 0px 24px;
  background: #093975;
  opacity: 0.6;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 20px;
  font-weight: 700;
  font-size: 15px;
}
.label_for_option {
  cursor: pointer;
}
.nextBtnSection {
  margin-top: 20px;
}
.carouselDynamicOptions {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #000000;
  padding-left: 200px;
  padding-top: 50px;
}
.flexOfImagesAndOptions {
  display: flex;
}
.error_message_carousel {
  color: #ff371b;
  font-size: 18px;
  margin-left: 20px;
}
