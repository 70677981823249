.navContainer {
  width: 100%;
  height: 100px;
  background-color: #112f54;
  position: relative;
}
@media screen and (max-width: 548px) {
  .navContainer {
    height: 75px;
  }
}
.spencerhallLogo {
  position: absolute;
  padding-top: 27px;
  padding-left: 54px;
}
@media screen and (max-width: 408px) {
  .spencerhallLogo {
    height: 50px;
    padding-top: 30px;
    padding-left: 4px;
    width: 130;
  }
}
.iConnectHead {
  font-family: "Open Sans", sans-serif;
  height: 52px;
  padding-top: 26px;
  font-weight: 700;
  font-style: italic;
  font-size: 30px;
  line-height: 51.75px;
  color: #ffffff;
  text-align: right;
  padding-right: 50px;
}
@media screen and (max-width: 548px) {
  .iConnectHead {
    font-family: "Open Sans", sans-serif;
    height: 22px;
    padding-top: 20px;
    font-weight: 700;
    font-style: italic;
    font-size: 18px;
    line-height: 25.75px;
    color: #ffffff;
    position: relative;
  }
  .spencerhallLogo {
    height: 50px;
    padding-top: 14px;
    padding-left: 4px;
    width: 130;
  }
}
