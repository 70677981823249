.girl_illustration {
  text-align: center;
}
.thanks_your {
  padding-top: 30px;
  text-align: center;
  font-size: 30px;
  color: black;
  font-weight: 700;
  font-family: "open sans";
}
.ok_Btn {
  padding: 5px 24px;
  background: #093975;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 80px;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 400;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thanks_margin {
  margin-bottom: 20px;
}
