@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}
.headerlogoutbtn {
  border: none;
  background-color: #ffff;
  font-style: bold;
  font-size: 12px;
  font-weight: 600;
}

.avatar {
  margin-left: 10px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  color: #da8517;

  padding-top: 8px;
  font-size: 24px;
}
