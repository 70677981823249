.studieslist {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #262626;
  margin-top: 30px;
  margin-left: 5%;
}
/* .ant-spin-nested-loading.css-dev-only-do-not-override-1i9hnpv {
  width: 1071px;
} */
.tablewidth {
  max-width: 95%;
  padding-left: 5%;
}
.css-dev-only-do-not-override-1i9hnpv.ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  color: #b4b4b4;
}
.css-dev-only-do-not-override-1i9hnpv.ant-table-wrapper .ant-table {
  font-family: "open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.searchandaddbtn {
  display: flex;
  margin-top: 45px;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #b4b4b4;
}

.searchinput {
  width: 270px;
  height: 40px;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #4f4f4f;
  background: #ffffff;
  margin-bottom: 50px;
  border-radius: 4px;
  border: 1.5px solid rgb(175 172 172 / 76%);
  padding-left: 28px;
  font-size: 14px;
}
.search-container {
  position: relative;
  display: inline-block;
}
.search-icon {
  position: absolute;
  left: 10px;
  top: 21px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #00000057;
}
.view_images_btn_contentComponent {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border: none;
  color: #da8517;
  background-color: white;
}
.loader {
  left: 50%;
  top: 40%;
  position: absolute;
}
