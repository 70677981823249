.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 5px;
  grid-gap: 10px;
  position: relative;
  height: 220px;
}
.view_images_btn_contentComponent {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border: none;
  color: #da8517;
  background-color: white;
  padding: 0px;
}
.view_images_btn_contentComponent:hover {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border: none;
  color: red;
  background-color: white;
}
.view_images_closebtn {
  background-color: #093975;
  border: 0.2px solid #6d6c6c;
  border-radius: 4px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 0px 25px;
}
.viewImages_background {
  height: 100px;
  width: 100px;
  background-image: url(../../../images/loadtwosmall.gif);
  background-repeat: no-repeat;
  background-position: center;
}
