.studyUrl {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border: none;
  color: #da8517;
  background-color: white;
  padding: 0px;
}
.studyUrl:hover {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border: none;
  color: #da8517;
  background-color: white;
}
