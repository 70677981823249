.deleteStudyCancelBtn {
  background: #ffffff;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #093975;
  padding: 10px 25px;
  border-radius: 4px;
}
.deleteStudySaveBtn {
  background: #093975;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.deleteStudySaveBtn_loading {
  background: #093975;
  opacity: 0.6;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.onceCompleted {
  font-family: "open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #565656;
}
.areYou {
  font-family: "open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #262626;
}
