.deleteInstructionSaveBtn {
  background: #093975;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.deleteInstructionSaveBtn_loading {
  background: #093975;
  opacity: 0.6;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.deleteInstructionCancelBtn {
  background: #ffffff;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #093975;
  padding: 10px 25px;
  border-radius: 4px;
}
