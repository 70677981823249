.checkboxParticipant {
  position: absolute;
  margin-left: 10px;
}
.selectOverSelectionOne {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 15px;
  grid-gap: 15px;
  position: relative;
}
.selectOverSelection {
  margin-left: 80px;
  margin-right: 40px;
  position: relative;
  margin-bottom: 20px;
}
.selectOverSelectionNextBtn {
  padding: 5px 24px;
  background: #093975;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 80px;
  margin-bottom: 40px;
}
.selectOverSelectionNextBtn_loading {
  padding: 5px 24px;
  background: #093975;
  opacity: 0.6;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 80px;
  margin-bottom: 40px;
}
.checkboxSelected {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
}
input.checkboxSelected {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.please_select {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  position: relative;
  margin-left: 77px;
  padding-top: 30px;
  padding-bottom: 20px;
  letter-spacing: 0.02em;
  color: #000000;
}
.five_images {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #000000;
}
.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader_style {
  width: 100px;
  height: 100px;
}
@media (max-width: 768px) {
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

@media (max-width: 480px) {
  input[type="checkbox"] {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
}
.brandsForSelection {
  color: #093975;
  font-weight: 800;
  font-size: 24px;
}
.borderForImages {
  border: 1px solid rgb(214, 206, 206);
}
.images_height_adjudsting {
  height: 15vw;
  background-image: url(../../../images/loadtwo.gif);
  background-repeat: no-repeat;
  background-position: center;
}
