.addstudybtn {
  background: #093975;
  border: 1px solid #093975;
  border-radius: 33px;
  font-family: "open Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
}
.addstudybtn:hover {
  background: #093975;
  border: 1px solid #093975;
  border-radius: 33px;
  font-family: "open Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  /* margin-bottom: 50px; */
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  margin-right: 0px !important;
}
.addinputField {
  width: 613px;
  height: 38px;
  margin-left: 30px;
  top: 108px;
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.classic {
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  width: 613px;
  height: 38px;
  font-size: 18px;
  margin-left: 30px;
  font-size: 14px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.classic:focus {
  min-width: 183px;
}
.signup-select {
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  width: 183px !important;
  height: 38px;
  font-size: 18px;
  margin-left: 30px;
  font-size: 14px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}

.signup-select:focus {
  min-width: 183px;
}

.signup-select {
  padding-right: 3rem !important;
}
.dateicon {
  font-size: 20px;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-left: 10px;
  background: #da8517;
  color: #ffffff;
}
.css-dev-only-do-not-override-1i9hnpv.ant-picker {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 11px !important;
}
.adddisabledselect {
  color: #bbb8b8;
}

.studyname {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 8px;
  color: #000000;
}
.css-dev-only-do-not-override-1i9hnpv.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 24px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "opensas", sans-serif;
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: 1px solid white !important;
  border-radius: 4px;
  height: 38px;
}
.ant-select-arrow {
  font-size: 10px !important;
  font-weight: 800;
  right: 3px !important;
}
.ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #bbb8b8 !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px !important;
  border-radius: 0px !important;
}
.ant-select-multiple .ant-select-selection-overflow-item {
  padding-left: 5px;
}
.browserbtn {
  background: #093975;
  border-radius: 4px;
  border: 1px solid black;
  color: white;
  margin-left: 30px;
  font-family: "openSans", sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
}
.browserbtn:hover {
  color: white !important;
  border: 1px solid black !important;
}
.ant-picker-panel-container {
  height: 270px !important;
  overflow: auto !important;
}

.adddataPicker {
  width: 613px;
  height: 38px;
  padding-right: 0px !important;
  margin-left: 30px;
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 7px 14px !important;
}
.css-15tb2jd-MuiFormControl-root {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}
button:disabled,
button[disabled] {
  background-color: #cccccc;
  cursor: not-allowed !important;
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  width: 0.8em !important;
  font-size: 1.5rem;
  right: 1px !important;
  color: black !important;
  font-weight: 800 !important;
}

.toast-message {
  color: white;
  font-size: 9px;
  background-color: #090808;
  width: 220px;
}
.addStudyCancelBtn {
  background: #ffffff;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #093975;
  padding: 10px 25px;
  border-radius: 4px;
}
.addStudySaveBtn {
  background: #093975;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.addStudySaveBtn_loading {
  background: #093975;
  opacity: 0.6;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.ant-select-selection-overflow {
  height: 38px !important;
}
.borderForImagesStudy {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}
.browsed_images {
  margin-left: 30px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 5px;
  grid-gap: 10px;
  position: relative;
}
.browser {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  grid-gap: 10px;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .browser {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-gap: 10px;
    position: relative;
  }
}
/* .browse {
  margin-left: 80px;
  margin-right: 40px;
  position: relative;
} */
.borderForImagesStudy {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}
.imageIcon {
  position: relative;
}
.wrongIcon {
  position: absolute;
  right: 5%;
  top: 5%;
}
.checkbox {
  position: absolute;
  margin-top: 25px;
  margin-left: 10px;
}
input.checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.ant-select-selection-item-remove svg {
  font-size: 15px !important;
  padding-bottom: 5px !important;
}
.selected_count {
  font-size: 20px;
  font-weight: 600;
  color: #da8517;
  padding-left: 10px;
}
.browsed_imaged_images {
  width: "100px";
  height: "100px";
  border: 1px solid rgb(220, 212, 212);
}
/* .Browsed_images_loading_add {
  height: 6vw;
  background-image: url(../../../images/loadtwo.gif);
  background-repeat: no-repeat;
  background-position: center;
} */
.deselect_btn {
  margin-left: 10px;
  border: none;
  background-color: #093975;
  color: #ffffff;
  text-align: center;
  margin-top: -10px;
  font-weight: 600;
}
.overall_browser {
  margin-top: 10px;
}
.searchinput_addStudy {
  height: 40px;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #3d3b3b;
  background: #e5e5e5;
  border-radius: 4px;
  border: 1px solid rgb(175 172 172 / 76%);
  padding-left: 20px;
  font-size: 14px;
  margin-left: 40px;
}
.css-2m9kme-MuiFormControl-root {
  margin-top: 0px !important;
}
.addinputFieldTwo {
  width: 456px;
  height: 38px;
  top: 108px;
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.selectIn_StudyTwo {
  width: 230px;
}
.css-e363es-MuiFormControl-root {
  margin-top: 0px !important;
}
.cancel_btn_in_addTwo {
  border: none;
  background-color: white;
}
.Add_question_two_btn {
  border: none;
  margin-left: 20px;
  margin-top: 15px;
}
.timeout_add {
  margin-left: 30px;
}
.Add_question_two {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
.Add_question_two:hover {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
.Add_question_Cancel_two {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-right: 5px;
}
.studynameTwo {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 8px;
  color: #000000;
}
.AddStudyContainerTwo {
  width: 800px;
}
.css-14lo706 {
  float: none !important;
  width: 0px !important;
}
.x_btn {
  font-size: 20px;
  color: #6d6c6c;
}
.backgroundLoading_images {
  height: 24vw;
  background-image: url(../../../images/loadtwosmall.gif);
  background-repeat: no-repeat;
  background-position: center;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  width: 230px !important;
  left: 703px;
}
.add_study_loader {
  margin-top: 300px;
  margin-left: 750px;
}
.css-h6iiar {
  margin: 0px 8px !important;
}
.css-1v4ccyo {
  height: 38px !important;
}
.overall_comparision_block {
  margin-left: 30px;
  height: 140px;
  margin-bottom: 10px;
}
.overall_addexplination_block {
  margin-left: 30px;
  margin-bottom: 10px;
}
.error_message_form {
  color: #ff371b;
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  margin-top: 10px;
  margin-left: 8px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
}
@media screen and (max-width: 1160px) {
  .add_study_loader {
    margin-top: 300px;
    margin-left: 450px;
  }
}
@media screen and (max-width: 860px) {
  .add_study_loader {
    margin-top: 300px;
    margin-left: 350px;
  }
}
@media screen and (max-width: 812px) {
  .add_study_loader {
    margin-top: 300px;
    margin-left: 250px;
  }
}
@media screen and (max-width: 670px) {
  .add_study_loader {
    margin-top: 300px;
    margin-left: 150px;
  }
}
@media screen and (max-width: 1612px) {
  .checkbox {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1574px) {
  .checkbox {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1474px) {
  .checkbox {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1374px) {
  .checkbox {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1274px) {
  .checkbox {
    margin-left: 10px;
    width: 17px;
    height: 17px;
  }
}
@media screen and (max-width: 1174px) {
  .checkbox {
    margin-left: 10px;
    width: 17px;
    height: 17px;
  }
}
@media screen and (max-width: 1074px) {
  .checkbox {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 974px) {
  .checkbox {
    margin-left: 10px;
  }
}
@media screen and (max-width: 874px) {
  .checkbox {
    margin-left: 10px;
    width: 1px;
    height: 10px;
  }
}
@media screen and (max-width: 774px) {
  .checkbox {
    margin-left: 10px;
    width: 8px;
    height: 8px;
  }
}
@media screen and (max-width: 674px) {
  .checkbox {
    margin-left: 10px;
    width: 5x;
    height: 5px;
  }
}
.no_filtered_data {
  font-size: 24px;
  font-weight: 400;
  position: absolute;
  left: 45%;
  padding-top: 100px;
}
.no_browse_data {
  font-size: 24px;
  font-weight: 400;
  position: absolute;
  left: 45%;
  padding-top: 100px;
}
.reactquill_explination {
  width: 456px;
  height: 100px;
  margin-bottom: 40px;
}
.react_quill_comparision {
  width: 456px;
  height: 100px;
  margin-bottom: 40px;
}
