.logincontainer {
  box-sizing: border-box;
  position: relative;
  width: 550px;
  height: 85%;
  margin-top: 50px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 1px 1px 14px 4px rgba(0, 0, 0, 0.1);
}
.row {
  margin-right: 0px !important;
}
/* .Item {
  padding: 20px 0px;
} */

.padding_for_div {
  padding: 10px 70px;
}
.iconnext_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.nextButton {
  background-color: #093975;
  padding: 10px 34px;
  margin-bottom: 50px;
  margin-top: 20px;
  margin-left: 110px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  width: 88px;
  height: 42px;
  left: 300px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  border: none;
}
.nextButton_loading {
  background-color: #093975;
  opacity: 0.6;
  padding: 10px 34px;
  margin-bottom: 50px;
  margin-top: 20px;
  margin-left: 110px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  width: 88px;
  height: 42px;
  left: 300px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  border: none;
}
.username {
  color: #6d6c6c;
  border-radius: 2px;
  padding: 10px;
}
.ant-input.css-dev-only-do-not-override-1i9hnpv {
  color: #6d6c6c;
  border-radius: 2px;
}
.css-dev-only-do-not-override-1i9hnpv.ant-form-horizontal
  .ant-form-item-control {
  max-width: 100% !important;
}
hr.hr-border {
  border: 1px solid #093975;
  opacity: 1 !important;
  width: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.border {
  box-sizing: border-box;
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.logintag {
  font-family: "open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 12px;
  text-align: center;
  font-variant: small-caps;
  color: #093975;
}
.form_formWidth {
  width: 60% !important;
}
@media only screen and (max-width: 769px) {
  hr {
    display: none;
  }
  .border {
    display: none;
  }
}
.toast-messages {
  color: white;
  font-size: 20px;
  background-color: #090808;
  width: 220px;
  z-index: 40;
}
.ant-col-16 {
  max-width: 100% !important;
}

.ant-form-item .ant-form-item-explain-error {
  color: #ff371b;
}
