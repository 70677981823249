.formContainer {
  margin-top: 20px;
  margin-left: 30px;
}
.formInput {
  background: #ffffff;
  color: #6d6c6c;
  height: 40px;
  width: 266px;
  border-radius: 2px;
}
.input {
  font-family: "Open Sans" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
}
.ant-form-item .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  padding-right: 30px;
}
.loginButton {
  background-color: #093975;
  padding: 5px 15px;
  border: 1px solid rgb(138, 136, 136);
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  color: white;
  width: 88px;
  height: 42px;
  margin-left: 18px;
  font: 15px;
  font-weight: 700;
}
.loginButton_loading {
  background-color: #093975;
  opacity: 0.6;
  padding: 5px 15px;
  border: 1px solid rgb(138, 136, 136);
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  color: white;
  width: 88px;
  height: 42px;
  margin-left: 18px;
  font: 15px;
  font-weight: 700;
}

@media screen and (max-width: 408px) {
  .buttonMargin {
    padding-top: 10px;
    margin-left: 80px;
  }
  .formContainer {
    margin-top: 10px;
  }
}
.css-dev-only-do-not-override-1i9hnpv.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
