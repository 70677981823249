.view_instruction_btn_contentComponent {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border: none;
  color: #da8517;
  background-color: white;
}
.view_instruction_btn_contentComponent:hover {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  border: none;
  color: red;
  background-color: white;
}
.view_instruction_closebtn {
  background-color: #093975;
  border: 0.2px solid #6d6c6c;
  border-radius: 4px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 0px 25px;
}
.view_instruction_closebtn:hover {
  background-color: #093975;
  border: 0.2px solid #6d6c6c;
  border-radius: 4px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 0px 25px;
}
.quill_component > .quill > .ql-container.ql-snow {
  border: none !important;
}
