.iconnectHeading {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 31px;
  text-align: center;
  margin-top: 30px;
  background: linear-gradient(152.03deg, #ffc881 -19.27%, #da8517 109.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 40px;
}
.bookicon {
  margin-right: 15px;
}
.bookicon a {
  color: #da8517;
}
.studies {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  margin-top: 10px;
}
.overAllSideList {
  margin-top: 100px;
}
.studies a {
  padding: 10px 102px 12px 18px;
  text-decoration: none !important;
  color: #7c7c7c;
}
a {
  color: #7c7c7c;
}
.link:hover {
  color: #7c7c7c;
  text-decoration: none;
}
.bookicon-studies {
  display: flex;
}
.helpicon-questions {
  display: flex;
}
.helpicon {
  margin-right: 15px;
}
.helpicon a {
  color: #da8517;
  margin-right: 15px;
}
.TextSnippetIcon {
  margin-right: 15px;
}
.TextSnippetIcon a {
  color: #da8517;
  margin-right: 15px;
}
.questionslist {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #7c7c7c;
  margin-top: 30px;
}
.questionslist a {
  padding: 10px 70px 12px 18px;
  text-decoration: none;
  min-width: 216px;
  min-height: 20px;
}
.reportslist {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #7c7c7c;
  margin-top: 30px;
}
.reportslist a {
  padding: 10px 102px 12px 18px;
  text-decoration: none !important;
  min-width: 216px;
  min-height: 20px;
  color: #7c7c7c;
}
.instructionslist {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #7c7c7c;
  margin-top: 30px;
}
.instructionslist a {
  padding: 10px 10px 12px 18px;
  text-decoration: none !important;
  min-width: 216px;
  min-height: 20px;
  color: #7c7c7c;
}
.active.link {
  background: linear-gradient(
    89.93deg,
    rgba(239, 197, 34, 0.28) 2.77%,
    rgba(218, 133, 23, 0.28) 109.06%
  );
  border-radius: 22.5px;
  color: #da8517;
  margin-left: 0px !important;
}
.active.link:hover {
  background: linear-gradient(
    89.93deg,
    rgba(239, 197, 34, 0.28) 2.77%,
    rgba(218, 133, 23, 0.28) 109.06%
  );
  border-radius: 22.5px;
  margin-left: 0px !important;
}
