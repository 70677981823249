.edit_instruction_name {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 8px;
  color: #000000;
}

.edit_instruction_options {
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  width: 50%;
  height: 38px;
  font-size: 18px;
  margin-left: 30px;
  font-size: 14px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.edit_instruction_options:focus {
  min-width: 183px;
}

.editInstructionSaveBtn {
  background: #093975;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.editInstructionSaveBtn_loading {
  background: #093975;
  opacity: 0.6;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.editSInstrictionCancelBtn {
  background: #ffffff;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #093975;
  padding: 10px 25px;
  border-radius: 4px;
}
.react_quilledit_container {
  width: 90%;
  margin-left: 30px;
  height: 250px;
}
.edit_instruction_name {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 8px;
  color: #000000;
}
