.please_select_the {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  position: relative;
  margin-left: 77px;
  padding-top: 30px;
  letter-spacing: 0.02em;
  color: #000000;
}
.please_select_the_five {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  position: relative;
  margin-left: 77px;
  padding-bottom: 20px;
  letter-spacing: 0.02em;
  color: #000000;
}
.five_Images {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #000000;
}
.brands_For_Selection {
  color: #093975;
  font-weight: 800;
  font-size: 24px;
}
.explanation_Next_Btn {
  padding: 5px 24px;
  background: #093975;
  border-radius: 4px;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 110px;
  margin-bottom: 40px;
}
.explanation_Next_Btn_loading {
  padding: 5px 24px;
  background: #093975;
  opacity: 0.6;
  border-radius: 4px;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 110px;
  margin-bottom: 40px;
}
.selectOverSelectionExplanaitonOne {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 15px;
  grid-gap: 15px;
  position: relative;
}
.selectOverSelectionExplanation {
  margin-left: 80px;
  margin-right: 40px;
  position: relative;
  margin-bottom: 20px;
}
.borderForExplanationImages {
  border: 1px solid rgb(214, 206, 206);
}
.data {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 80%;
  height: 75px;
  margin-left: 110px;
  background: #fcfdff;
  border: 0.2px solid #6d6c6c;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 400;
  padding-top: 15px;
  font-family: "Open Sans";
}
.explination_question_data {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 39px;
  letter-spacing: 0.02em;
  padding-left: 5px;
  color: #000000;
  display: flex;
  /* margin-top: 15px !important; */
}
.img_loader {
  height: 100px;
  width: 100px;
}

.error_message_explination {
  padding-left: 110px;
  color: #ff371b;
  font-size: 14px;
  font-weight: 600;
}
.adjust_images_height {
  height: 15vw;
  background-image: url(../../../images/loadtwo.gif);
  background-repeat: no-repeat;
  background-position: center;
}
.question_numbering {
  display: flex;
  /* align-items: center; */
  padding-left: 77px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 39px;
}
.question_numbering p {
  width: 98%;
}
