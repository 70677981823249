.reportslisttag {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #262626;
  margin-top: 30px;
  margin-left: 80px;
}
.netloader {
  left: 50%;
  top: 40%;
  position: absolute;
}
