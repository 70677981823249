.addinstructionbtn {
  background: #093975;
  border: 1px solid #093975;
  border-radius: 33px;
  font-family: "open Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

.addInstructionSaveBtn {
  background: #093975;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.addInstructionSaveBtn_loading {
  background: #093975;
  opacity: 0.6;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 4px;
}
.addInsructionCancelBtn {
  background: #ffffff;
  border: 0.2px solid #6d6c6c;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #093975;
  padding: 10px 25px;
  border-radius: 4px;
}
.search_and_addbtn {
  display: flex;
  margin-top: 45px;
}
.search-container-instruction {
  position: relative;
  display: inline-block;
}
.search-icon_instruction {
  position: absolute;
  left: 10px;
  top: 21px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #00000057;
}
.table_width {
  max-width: 95%;
  padding-left: 5%;
}
.instruction_listtag {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #262626;
  margin-top: 30px;
  margin-left: 5%;
}
.search_input {
  width: 270px;
  height: 40px;
  font-family: "open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #4f4f4f;
  background: #e5e5e5;
  margin-bottom: 50px;
  border-radius: 4px;
  border: 1px solid rgb(175 172 172 / 76%);
  padding-left: 20px;
  font-size: 14px;
}
.net_loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
.study_instruction_name {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 8px;
  color: #000000;
}
.instruction_options {
  background: #ffffff;
  border: 1px solid #bbb8b8 !important;
  border-radius: 4px;
  width: 50%;
  height: 38px;
  font-size: 18px;
  margin-left: 30px;
  font-size: 14px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 370;
}
.instruction_options:focus {
  min-width: 183px;
}

.react_quill_container {
  width: 90%;
  margin-left: 30px;
  height: 250px;
}
