.next_btn_instructons {
  padding: 5px 24px;
  background: #093975;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 20px;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 20px;
}
.blackHeading_instruction {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 18px;
  line-height: 38px;
  padding: 20px 30px;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #000000;
}
